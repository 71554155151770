import React from "react"
import Footer from "./Footer"

const Contact =() => {
    return(
        <div className="pt-3 my-2">
            <div className="pt-5 my-5">
            <Footer />
            </div>
        </div>
    )
}
export default Contact