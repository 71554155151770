let IMAGES = [
    {
      url:"imagesPub/image1.jpeg"
    },
    {
      url:"imagesPub/image2.jpeg"
    },
    {
      url:"imagesPub/image3.jpeg"
    },
    
   
  ]
export default IMAGES